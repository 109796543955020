import CountUp from "react-countup";
import FadeInWhenVisible from "./FadeIn";
import RightAnimate from "./RightAnimate";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Contact from "./Contact";
function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <main id="main">
        <FadeInWhenVisible>
          <section id="about" className="my-5 about">
            <div className="container">
              <div className="row gx-0">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <div className="content">
                    <h3>Who We Are</h3>
                    {/* <h2>
                  </h2> */}
                    {/* <p> */}
                    <h2>We are more than software programmers - </h2>
                    <p>
                      we are critical thinkers, problem-solvers, crafters of
                      change, and innovators of digital transformation. We help
                      businesses; small and big, to bring value to their end
                      customers, beat their competition and exponentially grow
                      as a successful company.
                    </p>
                    <p>
                      We pride ourselves for delivering world-class innovative
                      digital solutions that bring a great impact. We have
                      worked with top Fortune 500 companies, SMEs and Startups
                      to bring worth through technology, strategies and
                      creativity.
                    </p>
                    <p>
                      We are focused to keep our processes lean, transparent and
                      client-centric. We take great pride in our work quality
                      and proactive communication with which we keep bringing
                      tangible results for our customers.
                    </p>
                    <div className="text-center text-lg-start">
                      <a
                        href="#"
                        className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                      >
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center">
                  <img
                    src="/assets/img/about.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </FadeInWhenVisible>
        <section id="counts" className="counts">
          <FadeInWhenVisible>
            <div className="container">
              <header className="section-header">
                <h2>Our Analytics</h2>
              </header>

              <div className="row gy-4">
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-emoji-smile"></i>
                    <div>
                      <span className="purecounter"></span>
                      <p>Happy Clients</p>
                      <CountUp end={300}>+</CountUp>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext"></i>
                    <div>
                      <span className="purecounter"></span>
                      <p>Projects Delivered</p>
                      <CountUp end={251}>+</CountUp>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-headset"></i>
                    <div>
                      <span className="purecounter"></span>
                      <p>Hours Of Support</p>
                      <CountUp end={1500}></CountUp>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-people"></i>
                    <div>
                      <span className="purecounter"></span>
                      <p>Software Developers</p>
                      <CountUp end={50}>+</CountUp>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FadeInWhenVisible>
        </section>

        <section id="values" className="values">
          <div className="container">
            <header className="section-header">
              <h2>Our Values</h2>
              <p>How We Make A Difference?</p>
            </header>
            <RightAnimate>
              <div className="row">
                <div className="col-lg-4">
                  <div className="box">
                    <img
                      src="assets/img/values-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <h3>What We Do?</h3>
                    <p>
                      We pride ourselves for delivering world-class innovative
                      digital solutions that bring a great impact. We have
                      worked with top Fortune 500 companies, SMEs and Startups
                      to bring worth through technology, strategies and
                      creativity.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0">
                  <div className="box">
                    <img
                      src="assets/img/values-2.png"
                      className="img-fluid"
                      alt=""
                    />
                    <h3>How We Do ?</h3>
                    <p>
                      We take a time tested <b>Why-What-How</b> approach.
                      <br />
                      WHY Something Needs to be Done?
                      <br /> WHAT needs to be Done?
                      <br /> HOW it will be Done?
                      <br />
                      Execute it.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0">
                  <div className="box">
                    <img
                      src="assets/img/values-3.png"
                      className="img-fluid"
                      alt=""
                    />
                    <h3>Why Sam-Scripts?</h3>
                    <p>
                      We are focused to keep our processes lean, transparent and
                      client-centric. We take great pride in our work quality
                      and proactive communication with which we keep bringing
                      tangible results for our customers.
                    </p>
                  </div>
                </div>
              </div>
            </RightAnimate>
          </div>
        </section>
        <section id="features" className="features">
          <div className="container">
            <header className="section-header">
              <h2>Vision</h2>
              <p>What our vision is?</p>
            </header>

            <div className="row">
              <div className="col-lg-6">
                <img
                  src="assets/img/features.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Innovation and Technological Leadership</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Customer-Centric Solutions</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Sustainable Growth and Scalability</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Global Reach and Impact</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Empowering and Developing Talent</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Ethical and Responsible Practices</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RightAnimate>
              <div className="row feture-tabs">
                <div className="col-lg-6">
                  <h3>Our Mission</h3>

                  <ul className="nav nav-pills mb-3">
                    {/* <li>
                    <a className="nav-link active" href="#tab1">
                      Saepe fuga
                    </a>
                  </li> */}
                  </ul>

                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab1">
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-check2"></i>
                        <h4>Exceptional IT Solutions</h4>
                      </div>
                      <p>
                        Deliver top-notch, innovative IT products and services.
                      </p>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-check2"></i>
                        <h4>Commitment to Excellence</h4>
                      </div>
                      <p>
                        Maintain a high standard of quality in everything we do.
                      </p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-check2"></i>
                      <h4>Professional Growth</h4>
                    </div>
                    <p>
                      Invest in the development and growth of our employees.
                    </p>
                  </div>
                  <p>
                    Empowers your business by enhancing overall effectiveness
                    and efficiency using next generation technology. Our focus
                    is to help you envision potential, leverage technology and
                    reach for infinite growth.
                  </p>
                  <p>
                    To consistently lead technology innovation and provide
                    technology software services and solutions to global
                    clientele with exceptional quality, sharp turn around time
                    and cost effectiveness.
                  </p>
                </div>

                <div className="col-lg-6">
                  <img
                    src="assets/img/features-2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </RightAnimate>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <header className="section-header">
              <h2></h2>
              <p>Services</p>
            </header>

            <div className="row gy-4">
              <div className="col-lg-4 col-md-6">
                <div className="service-box blue">
                  <img src="/assets/img/design.png" />
                  {/* <i className="ri-discuss-line icon"></i> */}
                  <h3>Web Design</h3>
                  <p>
                    Effective web design blends aesthetics and functionality to
                    create engaging user experiences.
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-box orange">
                  <img src="/assets/img/development.png" />
                  <h3>Web Development</h3>
                  <p>
                    Web development involves coding, building, and maintaining
                    websites, focusing on functionality, usability, and
                    responsiveness for optimal user experience
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-box green">
                  <img src="/assets/img/marketing.png" />
                  {/* <i className="ri-discuss-line icon"></i> */}
                  <h3>Digital Marketing</h3>
                  <p>
                    Digital marketing leverages online channels like social
                    media and SEO to reach and engage audiences, driving brand
                    awareness and conversions
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-box red">
                  <img src="/assets/img/cloud.png" />
                  <h3>Cloud Services</h3>
                  <p>
                    Services that provide on-demand availability of computer
                    system resources, particularly data storage and computing
                    power, without direct active management by the user.
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-box purple">
                  <img src="/assets/img/customer-support.png" />
                  {/* <i className="ri-discuss-line icon"></i> */}
                  <h3>IT Consulting</h3>
                  <p>
                    Expert advice and strategies to help businesses optimize
                    their IT infrastructure and operations
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos-delay="700">
                <div className="service-box pink">
                  {/* <i className="ri-discuss-line icon"></i> */}
                  <img src="./assets/img/product-chain.png" />
                  <h3>Custom Product Development</h3>
                  <p>
                    Custom product development is a process where products are
                    designed and manufactured to meet the specific needs and
                    requirements of individual customers or businesses. This
                    approach contrasts with mass production, where products are
                    created for a broad audience with little variation.
                  </p>
                  <a href="#" className="read-more">
                    <span>Read More</span> <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="pricing">
          <div className="container">
            <header className="section-header">
              <h2>Pricing</h2>
              <p>Check our Pricing</p>
            </header>

            <div className="row gy-4">
              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <p>
                    Working by providing a demo development with the product
                    description
                  </p>
                  <h3>15 Days Trial</h3>
                  <div className="price">
                    <sup>$</sup>1200<span></span>
                  </div>
                  <img
                    src="assets/img/pricing-free.png"
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>Cordination and developmnent</li>
                    <li>Implementation and Integration</li>
                    <li class="na">Consultation</li>
                  </ul>
                  <a href="#" className="btn-buy">
                    Buy Now
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <span className="featured">Featured</span>
                  <h3>Standered Plan</h3>
                  <div className="price">
                    <sup>$</sup>2000<span> / mo</span>
                  </div>
                  <img
                    src="assets/img/pricing-starter.png"
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>Dedicated Developers(20 hrs/p.w)</li>
                    <li>Project Management</li>
                    <li>Communication by weekly meetings and updates</li>
                    <li>
                      Technology Stack (Frontend or backend or any service
                      development)
                    </li>
                  </ul>
                  <a href="#" className="btn-buy">
                    Buy Now
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3>Premium Plan</h3>
                  <div className="price">
                    <sup>$</sup>2800<span> / mo</span>
                  </div>
                  <img
                    src="assets/img/pricing-business.png"
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>Team of Developers</li>
                    <li>Project Management(Senior Dedicated Resource)</li>
                    <li>Communication Daily meetings</li>
                    <li>Technology Stack Fullstack with dedicated team</li>
                  </ul>
                  <a href="#" className="btn-buy">
                    Buy Now
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3>Enterprise Plan</h3>
                  <div className="price">
                    <sup>$</sup>3500<span> / mo</span>
                  </div>
                  <img
                    src="assets/img/pricing-ultimate.png"
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>Custom Development Team</li>
                    <li>Project Management</li>
                    <li>Communication Scrum meetings</li>
                    <li>Technology Stack Fullstack with dedicated team</li>
                    <li>Consultation Service</li>
                  </ul>
                  <a href="#" className="btn-buy">
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="faq" className="faq">
          <div className="container">
            <header className="section-header">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </header>

            <div className="row">
              <div className="col-lg-6">
                <div className="accordion accordion-flush" id="faqlist1">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Non consectetur a erat nam at lectus urna duis?
                      </button>
                    </h2>
                    <div
                      id="faq-content-1"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        Feugiat pretium nibh ipsum consequat. Tempus iaculis
                        urna id volutpat lacus laoreet non curabitur gravida.
                        Venenatis lectus magna fringilla urna porttitor rhoncus
                        dolor purus non.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Feugiat scelerisque varius morbi enim nunc faucibus a
                        pellentesque?
                      </button>
                    </h2>
                    <div
                      id="faq-content-2"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi?
                      </button>
                    </h2>
                    <div id="faq-content-3">
                      <div className="accordion-body">
                        Eleifend mi in nulla posuere sollicitudin aliquam
                        ultrices sagittis orci. Faucibus pulvinar elementum
                        integer enim. Sem nulla pharetra diam sit amet nisl
                        suscipit. Rutrum tellus pellentesque eu tincidunt.
                        Lectus urna duis convallis convallis tellus. Urna
                        molestie at elementum eu facilisis sed odio morbi quis
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="accordion accordion-flush" id="faqlist2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Ac odio tempor orci dapibus. Aliquam eleifend mi in
                        nulla?
                      </button>
                    </h2>
                    <div
                      id="faq2-content-1"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Tempus quam pellentesque nec nam aliquam sem et tortor
                        consequat?
                      </button>
                    </h2>
                    <div id="faq2-content-2">
                      <div className="accordion-body">
                        Molestie a iaculis at erat pellentesque adipiscing
                        commodo. Dignissim suspendisse in est ante in. Nunc vel
                        risus commodo viverra maecenas accumsan. Sit amet nisl
                        suscipit adipiscing bibendum est. Purus gravida quis
                        blandit turpis cursus in
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                      >
                        Varius vel pharetra vel turpis nunc eget lorem dolor?
                      </button>
                    </h2>
                    <div
                      id="faq2-content-3"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        Laoreet sit amet cursus sit amet dictum sit amet justo.
                        Mauris vitae ultricies leo integer malesuada nunc vel.
                        Tincidunt eget nullam non nisi est sit amet. Turpis nunc
                        eget lorem dolor sed. Ut venenatis tellus in metus
                        vulputate eu scelerisque. Pellentesque diam volutpat
                        commodo sed egestas egestas fringilla phasellus
                        faucibus. Nibh tellus molestie nunc non blandit massa
                        enim nec.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="portfolio" className="portfolio">
          <div className="container">
            <header className="section-header">
              <h2>Portfolio</h2>
              <p>Check our latest work</p>
            </header>

            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li className="filter-active">All</li>
                  <li>App</li>
                  <li>Card</li>
                  <li>Web</li>
                </ul>
              </div>
            </div>

            <div className="row gy-4 portfolio-container">
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-1.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 1</h4>
                    <p>App</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-1.jpg"
                        className="portfokio-lightbox"
                        title="App 1"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Web 3</h4>
                    <p>Web</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-2.jpg"
                        className="portfokio-lightbox"
                        title="Web 3"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-3.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 2</h4>
                    <p>App</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-3.jpg"
                        className="portfokio-lightbox"
                        title="App 2"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-4.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 2</h4>
                    <p>Card</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-4.jpg"
                        className="portfokio-lightbox"
                        title="Card 2"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-5.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Web 2</h4>
                    <p>Web</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-5.jpg"
                        className="portfokio-lightbox"
                        title="Web 2"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-6.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 3</h4>
                    <p>App</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-6.jpg"
                        className="portfokio-lightbox"
                        title="App 3"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-7.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 1</h4>
                    <p>Card</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-7.jpg"
                        className="portfokio-lightbox"
                        title="Card 1"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-8.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 3</h4>
                    <p>Card</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-8.jpg"
                        className="portfokio-lightbox"
                        title="Card 3"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-9.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Web 3</h4>
                    <p>Web</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-9.jpg"
                        className="portfokio-lightbox"
                        title="Web 3"
                      >
                        <i className="bi bi-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bi bi-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="testimonials" className="testimonials">
          <div className="container">
            <header className="section-header">
              <h2>Testimonials</h2>
              <p>What they are saying about us</p>
            </header>
            <Carousel responsive={responsive}>
              <div className="testimonials-slider swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="stars">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </div>
                      <p>
                        We've been working with SamScripts for several years
                        now, and they have consistently proven themselves to be
                        a reliable partner for all our IT needs. Their team is
                        proactive, responsive, and always goes above and beyond
                        to ensure our systems are running smoothly. Their
                        dedication to customer satisfaction is commendable, and
                        we look forward to continuing our partnership with them
                        in the future.
                      </p>
                      <div className="profile mt-auto">
                        <img
                          src="assets/img/testimonials/testimonials-1.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>David</h3>
                        <h4>Ceo &amp; Founder</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonials-slider swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <div className="stars">
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                        <i className="bi bi-star-fill"></i>
                      </div>
                      <p>
                        Our experience with SamScripts has been nothing short of
                        exceptional. From the initial consultation to the final
                        implementation, their team demonstrated unparalleled
                        professionalism and expertise. They took the time to
                        understand our unique requirements and delivered
                        innovative solutions that exceeded our expectations. We
                        highly recommend SamScripts to anyone seeking reliable
                        and top-notch IT services.
                      </p>
                      <div className="profile mt-auto">
                        <img
                          src="assets/img/testimonials/testimonials-2.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Liza</h3>
                        <h4>Product Development &amp; Head</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </section>
        {/* <section id="team" className="team">
          <div className="container">
            <header className="section-header">
              <h2>Team</h2>
              <p>Our hard working team</p>
            </header>

            <div className="row gy-4">
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Walter White</h4>
                    <span>Chief Executive Officer</span>
                    <p>
                      Velit aut quia fugit et et. Dolorum ea voluptate vel
                      tempore tenetur ipsa quae aut. Ipsum exercitationem iure
                      minima enim corporis et voluptate.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Sarah Jhonson</h4>
                    <span>Product Manager</span>
                    <p>
                      Quo esse repellendus quia id. Est eum et accusantium
                      pariatur fugit nihil minima suscipit corporis. Voluptate
                      sed quas reiciendis animi neque sapiente.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-3.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>William Anderson</h4>
                    <span>CTO</span>
                    <p>
                      Vero omnis enim consequatur. Voluptas consectetur unde qui
                      molestiae deserunt. Voluptates enim aut architecto porro
                      aspernatur molestiae modi.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-4.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Amanda Jepson</h4>
                    <span>Accountant</span>
                    <p>
                      Rerum voluptate non adipisci animi distinctio et deserunt
                      amet voluptas. Quia aut aliquid doloremque ut possimus
                      ipsum officia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="clients" className="clients">
          <div className="container">
            <header className="section-header">
              <h2>Our Clients</h2>
            </header>

            <div className="clients-slider swiper">
              <div className="swiper-wrapper align-items-center">
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-4.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-5.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-6.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-7.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src="assets/img/clients/client-8.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section> */}
        {/* <section id="recent-blog-posts" className="recent-blog-posts">
          <div className="container">
            <header className="section-header">
              <h2>Blog</h2>
              <p>Recent posts form our Blog</p>
            </header>

            <div className="row">
              <div className="col-lg-4">
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/blog/blog-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <span className="post-date">Tue, September 15</span>
                  <h3 className="post-title">
                    Eum ad dolor et. Autem aut fugiat debitis voluptatem
                    consequuntur sit
                  </h3>
                  <a
                    href="blog-single.html"
                    className="readmore stretched-link mt-auto"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/blog/blog-2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <span className="post-date">Fri, August 28</span>
                  <h3 className="post-title">
                    Et repellendus molestiae qui est sed omnis voluptates magnam
                  </h3>
                  <a
                    href="blog-single.html"
                    className="readmore stretched-link mt-auto"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="post-box">
                  <div className="post-img">
                    <img
                      src="assets/img/blog/blog-3.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <span className="post-date">Mon, July 11</span>
                  <h3 className="post-title">
                    Quia assumenda est et veritatis aut quae
                  </h3>
                  <a
                    href="blog-single.html"
                    className="readmore stretched-link mt-auto"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Contact />
      </main>
    </>
  );
}

export default Home;
