import { motion, useViewportScroll, useTransform } from "framer-motion";

function RightAnimate({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.4, type: "spring", stiffness: 500 }}
      variants={{
        hidden: { opacity: 0.6, x: 150 },
        visible: { opacity: 1, x: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}
export default RightAnimate;
